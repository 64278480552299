
export default {
  "description": "Cookie policy",
  "paragraph": [
    {
      "title": "クッキーポリシー",
      "description": "このサイトを適切に機能させるために、Cookieと呼ばれる小さなデータファイルをデバイスに配置することがあります。"
    },
    {
      "title": "クッキーとは?",
      "description": "Cookieは、WebサイトにアクセスしたときにWebサイトがコンピューターまたはモバイルデバイスに保存する小さなテキストファイルです。これにより、Webサイトは一定期間にわたってユーザーのアクションと設定（ログイン、言語、フォントサイズ、その他の表示設定など）を記憶できるため、サイトに戻ったときにそれらを再入力し続ける必要はありません。あるページから別のページに閲覧します。",
    },
    {
      "title": "使用用途",
      "description": "サービスへのアクセス方法と使用方法に関する情報を収集する場合があります。この使用状況データには、コンピューターのインターネットプロトコルアドレス（IPアドレスなど）、ブラウザーの種類、ブラウザーのバージョン、アクセスしたサービスのページ、アクセスした日時、それらのページで費やした時間などの情報が含まれる場合があります。",
    }
  ]
}