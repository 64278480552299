import img from '../assets/images/404.png';

export default [
  {
    'title': '404',
    src: img,
    texts: ['NOT', 'FOUND'],
    animated: true,
  }
];
